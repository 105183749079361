import Axios from 'axios';
import Auth from '@/services/AuthService';
import RTE from '@/rte';
import Store from '@/store';

//
// Creates a global http method to use for making request to the server
//

let successStatuses = [
  200,
  201,
  202,
  203,
  204,
  205,
  206,
  207,
  208,
  226
]

var HTTP = {
  http: function(returnAllErrors = false) {
    var token = (Store.state.tokenDetail != null) ? "Bearer " + Store.state.tokenDetail.JWT : null;
    var axios = Axios.create({
        baseURL: Store.state.apiUrl,
        headers: {
            Authorization: token,
        },
        validateStatus: function (status) {
          if (status === 401) {
            Auth.logout();
            if (window.location.pathname != "/login") {
              window.location.replace(RTE.getBaseURL());
            }
            return false;
          }

          if (returnAllErrors) {
            return successStatuses.includes(status);
          }
          else {
            return true;
          }
        },
    });
    return axios;
  }
}

export default HTTP;
