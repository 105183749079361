import Store from '@/store'
import HTTP from './HttpService'

var Auth = {

  getToken: function() {
    return Store.state.tokenDetail;
  },

  login: async function(user) {
    var tokenDetail;

    await HTTP.http().post('/authenticate', user)
      .then(function(response) {
        if (response) {
          tokenDetail = response.data;
          Store.dispatch('setToken', { payload: tokenDetail });
        }
      })
      .catch(function(error) {
        console.log('Authentication error: ' + error);
    });

    return tokenDetail;
  },

  logout: function() {
    Store.dispatch('clear');
  }

}

export default Auth;
